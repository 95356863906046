<template>
  <div id="app1">
    <div >
      <div class="header P_2" @click="goPrev">
          <img src="../assets/icon30.png" alt="">
          <span>信用质量评价</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/banner1@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">企业信用质量评分</div>
        <div class="txt2">{{Number( scoreItem.credit_quality_score).toFixed(1)}} <span>分</span></div>
        <div class="model1">
          <div class="title">指数解读</div>
          <div class="subTitle">信用质量指数是基于以下纬度生成</div>
          <div class="btns">
            <div class="btn2" @click="goDetail(1,'外部环境')">外部环境</div>
            <div class="btn2" @click="goDetail(1,'企业特质')">企业特质</div>
            <div class="btn2" @click="goDetail(1,'股东背景')">股东背景</div>
            <div class="btn2" @click="goDetail(1,'经营风险')">经营风险</div>
            <div class="btn2" @click="goDetail(1,'负面信息')">负面信息</div>
            <div class="btn2" @click="goDetail(1,'利益关联方风险')">利益关联方风险</div>
          </div>
            <div>
              <div class="title2">本次企业信用评价为：<span style="color:#151B62">{{content2}}</span> </div>
              <div class="txt">{{content4}}</div>
            </div>
          <div class="title2" style="padding-bottom:1.3rem;">企业信用指数发展趋势为：<span style="color:#151B62">增长</span> </div>
          
        </div>
       <!-- <div class="model2">
          <div>
            <div class="title">本次企业信用评价为：<span style="color:#151B62">极好</span> </div>
            <div class="txt">{{content2}}</div>
          </div>
            <div  id="main" style="width: 25rem; height:10rem;"></div>  
           <img src="../assets/jingyingtoumingzhishuimag@2x.png" alt=""> 
        </div>-->
        <div class="model3">
          <div>
            <div class="title">评价说明</div>
            <div class="companyTitle title" style="margin-top:0;padding:1rem 0">
              <div class="dot"></div>
              <div style="margin-left:0.5rem; font-weight: 600;">{{content3}}</div> 
            </div>
            <div class="txt">{{content1}}</div>
          </div>
        </div>
        <div style="height:5rem"></div>
      </div>
    </div>
    
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;">返回 力度分</div>  
      <!-- <div class="btn MR_2" @click="goPrev">上一页</div>  
       -->
      <!-- <div class="btn" style="border-top-right-radius: 5rem;border-bottom-right-radius: 5rem;">详细信息</div>   -->
    </div>

    
  </div>
</template>
<script>
import * as echarts from 'echarts';
import {mapActions, mapState,} from "vuex"
import { Toast } from 'vant';
export default {
  mounted(){
     let {credit_quality_score,credit_quality_level,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score} = this.scoreItem
    //  let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
    //  this.initEacharts(arr)
     this.getContent(credit_quality_level)
  },
  computed:{
    ...mapState({
      scoreItem:  (state) => state.scoreItem,
    }),
  },
  data(){
    return {
      content1:"", //评价
      content2:"", //能力
      content3:"", //评价标题
      content4:"",
    }
  },
  methods:{
    ...mapActions(["getLmxDesc"]),
    goDetail(type,content){
      this.$router.push({path: "/getLmxDesc",query:{
        type,
        content,
      }})
    },
    getContent(val){
      console.log(val)
      switch (val){
        case 'A':
            this.content1 = "诚信经商，信赢天下，该企业信用质量极好，千万别错过～"  
            this.content2 = "极好"
            this.content4 = "企业诚信经商，近年来没有违规违纪失信行为，是行业标杆。"  
            this.content3 = "最强王者，行业标杆。"  
            break;
        case 'B':
            this.content1 = "诚德铸造品牌，信誉开辟市场，该企业信誉质量很好，快快投递简历吧～"  
            this.content2 = "优秀"
            this.content4 = "企业信用优秀，很少有内外部信用风险，值得信赖。"  
            this.content3 = "璀璨钻石，信守不渝。"  
            break;
        case 'C':
            this.content1 = "以诚为立世之根，以信为基业之本，该企业信誉质量良好，可以收藏哦～" 
            this.content2= "良好" 
            this.content4 = "企业信用良好，积极面对外部风险鲜有负面信息，稳步发展。"  
            this.content3 = "华贵铂金，值得信赖。"  
            break;
        case 'D':
            this.content1 = "该企业信用不错，但需注意外部环境风险，可以多多观察一段时间哦。" 
            this.content2= "中等" 
            this.content4 = "企业信用不错，仍需时刻注意商业环境及经济变化，未来可期。"  
            this.content3 = "荣耀黄金，未来可期。"  
          break;
         case 'E':
            this.content1 = "企业经营需抱诚守真，该企业在信用质量提升上还有很大发展空间～" 
            this.content2 = "较差" 
            this.content4 = "诚信可贵，企业正在努力提升风险对应能力。"  
            this.content3 = "坚韧黄铜，再接再厉。"  
          break;
        default:
            
        }
         
       
    },
    goPrev(){
      this.$router.push("/reportDetail")
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
      option = {
      radar: {
        indicator: [
            { name: '信用质量评分', max: 1000, color:"#151B62",  },
            { name: '生产能力评分', max: 1000 },
            { name: '企业透明指数', max: 1000 },
            { name: '生态链健康指数', max: 1000 },
            { name: '同业竞争评分', max: 1000 },
            { name: '经营能力评分', max: 1000 }
        ],
        center: ['50%', '45%'],
        radius: 25,
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: arr,
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(21,27,98, 0.1)',
                  offset: 0 
                },
                {
                  color: 'rgba(21,27,98, 0.9)',
                  offset: 1
                }
              ])
            }
          }
      ]
      }]
    };
    myChart.setOption(option); 
    },
     
  }
}
</script>
<style lang="scss" scoped>
#app1{
  background-color: #fff;
}
 .companyTitle{
  display: flex;
  align-items: center;
 }
.btns{
  width:100%;
}
.btn2{
  width:45%;
  text-align: center;
}
</style>